.secretField {
    font-size: 12px;
    width: 100%;
    color: red;
    padding-top: 5px;
    line-height: 1.0em;
    word-break: break-all;
}
.openCodeTitle {
    color: blue;
}
.secretCodeTitle {
    color: blue;
}
.empty {
    visibility: hidden;
}
.notEmpty {}
