.buttonGeneral {
    margin-left: 0px;
    margin-right: 0px;
    width: 32%;
    height: 30px;
    text-align: center;
}

.buttonTeaCoffee {
    margin-left: 2%;
    margin-right: 2%;
    width: 32%;
    height: 30px;
    text-align: center;
}
.openCodeTitle {
    font-size: 12px;
    color: blue;
}
.openCode {
    font-size: 12px;
    color: red;
}

.buttonTake {
    width: 45%;
    height: 30px;
    text-align: center;
    margin-right: 10%;
    color: white;
    background-color: red;

}
.buttonTakeDisable {
    width: 45%;
    height: 30px;
    text-align: center;
    margin-right: 10%;
    color: white;
    background-color: #ffc0c0;
}

.buttonAsk {
    width: 45%;
    height: 30px;
    text-align: center;
    color: white;
    background-color: red;
}
.buttonAskDisable {
    width: 45%;
    height: 30px;
    text-align: center;
    color: white;
    background-color: #ffc0c0;
}

.buttonActive {
    background-color: yellow;
    color: green;
}
.buttonNonActive {
    color: black;
}
.pultEmpty {
    display: none;
    /*visibility: hidden;*/
    /*height: 0px;*/
}
.pult {
    margin-top: 5px;
}


.startGame {
    margin-top: 10px;
    margin-bottom: 10px;

}
.buttonNewGame {
    width: 45%;
    height: 30px;
    font-size: 20px;

}
.buttonEndGame {
    width: 45%;
    height: 30px;
    font-size: 20px;
    margin-left: 10%;
}
.buttonEndGameDisable {
    width: 45%;
    height: 30px;
    font-size: 20px;
    margin-left: 10%;
    color: #d0d0d0;
}
