.paramTitle{
    width: 50px;
    text-align: left;
    font-style: italic;
}
.paramName {
    text-align: left;
    margin-left: 10px;
}

.paramValue {
    width: 30px;
    text-align: left;
}
.Fee {
    margin-left:3px;
}
.Stat {
    position: absolute;
    /*width: 100%;*/
    right: 0%;
}
.classHint {
    position: absolute;
    width: 100%;
    text-align: right;
    /*margin-left: 10px;;*/
}
.empty {
    visibility: hidden;
}
