.main
{
    font-family: sans-serif;
}
.clIsOneRow {

}
.toMainGame
{
    margin: 5px;
    width:  27.7rem;
    height: 1.5rem;
    background-color: yellow;
    border-radius: 5px;
}
.description {
    font-family: monospace;
    white-space: pre;
    /*font-size: '10px;*/
}
.realization {
    font-family: monospace;
    white-space: pre;
    /*font-size: '10px;*/
}
.buttonDescription
{
    margin: 5px;
    width:  13.5rem;
    height: 1.5rem;
    background-color: yellow;
    border-radius: 5px;
}
.seria
{
    margin-bottom: 5px;
    height: 1.5rem;
}
.edit
{
    width:7.6rem;
}
.play
{
    margin: 5px;
    width:  13.5rem;
    height: 1.5rem;
    background-color: yellow;
    border-radius: 5px;
}

.total
{
    font-family: monospace;
    white-space: pre;
}

.buttonPrize  {
    /*font-family: monospace;*/
    margin: 0;
    padding: 0;

    padding-right: 5px;
    text-align: right;

    width: 40px;
}
.buttonGames  {
    /*font-family: monospace;*/
    margin: 0;
    padding: 0;

    padding-right: 5px;
    text-align: right;

    width: 80px;
}
.butPrize  {
    /*font-family: monospace;*/
    background-color: grey;
    color: white;
    margin: 0;
    padding: 0;

    padding-right: 5px;
    text-align: right;

    width: 40px;
}
.butGames  {
    /*font-family: monospace;*/
    background-color: grey;
    color: white;
    margin: 0;
    padding: 0;

    padding-right: 5px;
    text-align: right;

    width: 80px;
}
.buttonGist  {
    margin: 0;
    padding: 0;

    height: 1rem;
    background-color: green;
}
.buttonPlay {
    width:70px;
}