.paramTitle{
    width: 50px;
    text-align: left;
    font-style: italic;
}
.paramName {
    text-align: left;
    margin-left: 10px;
}

.paramValue {
    width: 30px;
    text-align: left;
}
