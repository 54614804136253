.Row {
    position: relative;
    width: 100%;
    height: 1em;
}
.general {
    position: absolute;
    width: 100%;
}
.Game       {text-align: center;}
.Prize      {text-align: right;}
.PrizeMax   {text-align: left;}

.empty {visibility: hidden;}
