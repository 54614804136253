.buttonGeneral {
    margin: 0px;
    padding-top: 0px;
    padding: 0px;
    border-radius: 5px;
    width: 15%;
    height: 40px;
    text-align: center;
    font-size: 11px;
    overflow: hidden;
}
.buttonRow5 {
    width: 18%
}

.buttonRow4 {
    width: 22.5%
}

.buttonRow3 {
    width: 30%
}

.buttonEnabled {
    border-width: 5px;
    border-color: cyan;
}
.answerNothing {
    background-color: white;
}
.answerTrue {
    color: white;
    background-color: green;
}
.answerFalse {
    background-color: red;
    color: white;
}
.answerHalfTrue {
    background-color: #fff000;
    color: green;
}
.answerWater {
    background-color: black;
    color: white;
}
.Tea {
    background-color: orange;
    color: white;
}
.Coffee {
    background-color: brown;
    color: white;
}
.Water {
    background-color: deepskyblue;
    color: white;
}
.nextServed {
    color: red;
    overflow: hidden;
}
