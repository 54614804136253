.startGame {
    margin-top: 10px;
    margin-bottom: 10px;
}
.buttonNewGame {
    width: 45%;
    height: 30px;
    font-size: 20px;

}
.buttonEndGame {
    width: 45%;
    height: 30px;
    font-size: 20px;
    margin-left: 10%;
}
.classDisabled {
    background-color: #d0d0d0;
}


/*
.buttonEndGameDisable {
    width: 45%;
    height: 30px;
    font-size: 20px;
    margin-left: 10%;
    color: #d0d0d0;
}
*/
